#root {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  /* background: linear-gradient(#fff, #999); */
  background: linear-gradient(#4047cd, #f4793f);
}

* {
  user-select: none;
}

#root>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app {
  overflow: hidden;
}

.app>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  flex-direction: row !important;
}

.row>* {
  margin: 5px;
  margin-top: 1vw;
}

h1 {
  font-family: 'Roboto', sans-serif;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
}

h2 {
  color: #fff;
}

.swipe {
  position: absolute;
}

.statsInfo{
  margin-top: -.8vw;
  color: white;
}

.cardContainer {
  width: 90vw;
  max-width: 380px;
  height: 480px;
}

.cellNumber{
  background-color: rgba(0, 0, 0);
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
  border-radius: 10px;
}

.card {
  position: relative;
  background-color: #fff;
  width: 80vw;
  max-width: 380px;
  height: 480px;
  
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  margin-bottom: 1vw;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.swipe:last-of-type {

}

.card h2 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
  margin-bottom: 1vw;
}

.personinfo{
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  padding: .3vw;
}

.infoText {
  margin-top: -.8vw;
  width: 100%;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}
.matchbuttons{
  margin-top: 3vw;
}

.ikThisIsBad{
  margin-bottom: -4vw;
}
.buttons {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 625px) {
  .buttons {
    flex-direction: column;
  }
}

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  background-color: #9198e5;
  transition: 200ms;
  margin: 10px;
  font-weight: bolder;
  width: 160px;
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.10);
}

.buttons button:hover {
  transform: scale(1.05);
}

@keyframes popup {
  0%   { transform: scale(1,1) }
  10%  { transform: scale(1.1,1.1) }
  30%  { transform: scale(.9,.9) }
  50%  { transform: scale(1,1) }
  57%  { transform: scale(1,1) }
  64%  { transform: scale(1,1) }
  100% { transform: scale(1,1) }
}
